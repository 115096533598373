div > span {
    font-family: cocomat;
}

.ap-metal-logo:hover {
    animation: Tilting 0.75s ease-out;
    transform: scale(1.1);
}

@keyframes Tilting {
    0% {
        transform: rotate(1deg) scale(1.01);
    }
    10% {
        transform: rotate(-1deg) scale(1.02);
    }
    20% {
        transform: rotate(1deg) scale(1.03);
    }
    30% {
        transform: rotate(-1deg) scale(1.04);
    }
    40% {
        transform: rotate(1deg) scale(1.05);
    }
    50% {
        transform: rotate(-1deg) scale(1.06);
    }
    60% {
        transform: rotate(1deg) scale(1.07);
    }
    70% {
        transform: rotate(-1deg) scale(1.08);
    }
    80% {
        transform: rotate(1deg) scale(1.09);
    }
    90% {
        transform: rotate(-1deg) scale(1.1);
    }
    100% {
        transform: rotate(0deg) scale(1.1);
    }
}
