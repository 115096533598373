.handwriting {
    font-family: monospace;
    font-size: 3rem;
    color: grey;
}

.stage-container {
    cursor: pointer;
}

.underline-active {
    border-bottom: 2px solid gray;
}

.scale {
    transform: scale(1.1);
    transition: 0.05s;
}
