.title {
    animation-name: appear;
    animation-duration: 1s;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translate(-50%, -20%);
        left: 50%;
        top: 50%;
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }
}

h2 {
    font-family: good-times;
}
