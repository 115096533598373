.card-item {
    z-index: -1;
    border: 1px solid darkgray;
    border-radius: 15px;
    width: 250px;
    height: 300px;
    box-shadow: 3px 15px 7px #80808063;
}

.card-item-text {
    font-weight: normal;
}

.blue-background {
    background-image: linear-gradient(-130deg, white, #003eff0d, white)
}

.gray-background {
    background-image: linear-gradient(-130deg, white, #80808024, white)
}

.green-background {
    background-image: linear-gradient(-130deg, white, #00804012, white)
}

/* normal button style */
.shiney {
    text-align: center;
    position: relative;
    -webkit-appearance: none;
    border: none;
    overflow: hidden;
}

/* button hover style if required */
.shiney:hover {
    transform: scale(1.05);
    transition: .05s ease-out;
}

/* generated element for shine effect.
* normal state is semi-transparent
* white but with zero width. Set no
* transition here for no mouse-leave
* animations. Otherwise the effect
* will play in reverse when your mouse
* leaves the element
*/
.shiney:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    transition: none;
}

/* on hover we animate the width to
* 100% and opacity to 0 so the element
* grows and fades out
*/
.shiney:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.3s ease-out;
}
