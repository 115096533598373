.statistic-item {
    border: lightgrey 1px solid;
    background-color: #d3d3d36b;
    border-radius: 100%;
    box-shadow: 8px 4px 2px #80808024;
}

.show-more-less-clickable {
    text-decoration-line: underline;
    color: grey;
}

.text > span {
    font-size: 18px;
    font-family: 'open sans', sans-serif, "Helvetica Neue";
    font-weight: normal;
}
