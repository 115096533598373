footer > div {
    color: white;
}

footer > div, div > span {
    font-family: 'open sans', sans-serif, "Helvetica Neue";
    font-weight: normal;
}

.footer-subtitle {
    cursor: pointer;
    font-size: larger;
}

.footer-subtitle:hover {
    text-decoration-line: underline;
}

.vl {
    border-left: 1px solid white;
    height: 10rem;
}
