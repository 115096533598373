@font-face {
    font-family: good-times;
    src: url("../fonts/good-times-rg.otf");
}

@font-face {
    font-family: Open Sans;
    src: url("../fonts/open-sans.ttf");
}

@font-face {
    font-family: Helvetica Neue;
    src: url("../fonts/helvetica-neue.otf");
}

@font-face {
    font-family: Pacifico;
    src: url("../fonts/Pacifico-Regular.ttf");
}


@font-face {
    font-family: Damion;
    src: url("../fonts/Damion-Regular.ttf");
}

.app {
    text-align: center;
}


h1 {
    font-size: 6rem;
    font-family: good-times;
}

.dark-background {
    background-color: #282c34;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    overflow: hidden;
}

.absolute-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.subtitle {
    font-family: 'open sans', sans-serif, "Helvetica Neue";
    font-weight: normal;
}

p {
    font-size: 18px;
    font-family: 'open sans', sans-serif, "Helvetica Neue";
    font-weight: normal;
}
